html, body {
  background-image: linear-gradient(90deg, rgb(6, 147, 227) 29%, rgb(155, 81, 224) 100%) !important;
  background-color: rgb(236, 230, 220) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-attachment: scroll !important;
  width: 100%;
  /* Adjust the opacity as needed */
  z-index: -1 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'gotham-rounded-bold';
  src: local('gotham-rounded-bold'), url(./fonts/gotham-rounded-bold.otf) format('opentype');
  /* Adicione outras variações e formatos da fonte, se necessário */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
